import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from 'components/Button';
import CTA from 'components/CTA';
import styles from './heroSection.module.scss';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import YouTubePlayer from 'components/YoutubePlayer';
import BeFirstToKnowIcon from '../../../static/svg/BeFirstToKnowIcon';
import mobileButtonStyles from 'features/vehicleDetails/vehicle-details/vehicleDetails.module.scss';
const buttonProps = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

const HeroSection = ({
  imagePath,
  mobileImagePath,
  title,
  leftButtons,
  description,
  subTitles,
  footer,
  callToActions,
  image,
  isTextWithOrPrefix,
  titleWithoutMargin,
  titleFullWidth,
  children,
  onClick,
  subTitle,
  video,
  displayDescriptionPlaceholder = false,
  isAuctionDetails = false,
  isMobile = false,
  toggleModal,
  pageTitle,
}) => {
  const [innerHtml, setInnerHtml] = useState();
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  useEffect(() => {
    setInnerHtml(description);
  }, [description]);
  return (
    <div
      className={`${styles.appHeroSection}`}
      onClick={onClick && onClick}
      role="presentation"
      id="my-component"
    >
      <div className={styles.heroWrapper}>
        {isAuctionDetails && isMobile && (
          <Button
            handleClick={e => {
              e.stopPropagation();
              toggleModal();
            }}
            type={`${styles.mobileButton}`}
          >
            <BeFirstToKnowIcon stroke={'white'} />
          </Button>
        )}
        {imagePath && (
          <picture>
            <source
              media="(max-width:550px)"
              srcSet={mobileImagePath || imagePath}
            />
            <img src={imagePath} alt={image?.description} />
          </picture>
        )}

        {image && !video && (
          <GatsbyImage
            image={image?.fluid}
            title={image?.title}
            alt={image?.description ?? image?.alt}
            className={styles.gatsbyImageWrapper}
          />
        )}

        {video && (
          <div className={styles.heroVideoWrapper}>
            <YouTubePlayer url={video} controls={true} />
          </div>
        )}
      </div>
      <div
        className={`${styles.wrapper} ${
          titleFullWidth ? styles.wrapperFullWidth : ''
        } ${isAuctionDetails ? styles.leftAlign : ''}`}
      >
        {title && <h1 className={titleWithoutMargin ? 'mb-0' : ''}>{title}</h1>}
        {subTitle && <h2>{subTitle}</h2>}
        {innerHtml ? (
          <div dangerouslySetInnerHTML={{ __html: innerHtml }}></div>
        ) : displayDescriptionPlaceholder ? (
          <div className={styles.placeHolder}></div>
        ) : (
          ''
        )}
        {subTitles && subTitles.length && (
          <div className={styles.subTitlesGroupWrapper}>
            {subTitles.map((subTitle, index) => {
              if (Array.isArray(subTitle)) {
                return (
                  <div
                    className={styles.subTitles}
                    key={`subTitleContainer${index}`}
                  >
                    {subTitle &&
                      subTitle.length > 0 &&
                      subTitle.map((row, index) => (
                        <p key={`subTitleWrapper${index}`}>{row.title}</p>
                      ))}
                  </div>
                );
              } else {
                return (
                  <div
                    className={styles.subTitlesGroup}
                    key={`subTitleContainer${index}`}
                  >
                    {subTitle.bold ? (
                      <p className={styles.subTitles}>{subTitle.title}</p>
                    ) : (
                      <h2 key={`subTitleContainer${index}`} className="mb-0">
                        {subTitle.title}
                      </h2>
                    )}
                  </div>
                );
              }
            })}
          </div>
        )}

        <div
          className={`${styles.buttonGroupWrapper} ${
            isAuctionDetails ? styles.columnView : ''
          }`}
        >
          {leftButtons &&
            leftButtons.map((button, index) => (
              <Button
                type="app-transparent-white-button"
                key={`heroSectionButton_${index}`}
                handleClick={() =>
                  _.isFunction(button.onClick) && button.onClick()
                }
                linkAsButton={button.onClick ? false : true}
                path={button.path}
              >
                {button.title}
              </Button>
            ))}
          {callToActions?.length && (
            <CTA
              isTextWithOrPrefix={isTextWithOrPrefix}
              callToActions={callToActions}
              title={title}
              pageTitle={pageTitle}
            />
          )}
        </div>

        {footer && (
          <Button
            type="app-white-link-button"
            handleClick={() => _.isFunction(footer.onClick) && footer.onClick()}
          >
            {footer.title}
          </Button>
        )}
      </div>

      {children}
    </div>
  );
};

HeroSection.propTypes = {
  imagePath: PropTypes.string,
  mobileImagePath: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.string,
  leftButtons: PropTypes.arrayOf(PropTypes.shape(buttonProps)),
  footer: PropTypes.shape(buttonProps),
};

export default HeroSection;
